h2, .h2{
  @include media-breakpoint-down(lg){font-size: 1.5rem;}
  @include media-breakpoint-down(sm){font-size: 1.2rem;}
}

p{
  @include media-breakpoint-down(md){font-size: 0.8rem;}
}

.elephant-grey-title{
  color: $elephant-grey;
  font-size: 4rem;
  @include media-breakpoint-down(lg){font-size: 3rem;}
  @include media-breakpoint-down(md){font-size: 2rem;}
  @include media-breakpoint-down(sm){font-size: 1.5rem;}

  &.padding-y{
    padding-top: 8rem;
    padding-bottom: 2rem;
  }
}
