.gradient-vertical{
  background: linear-gradient(to bottom,  rgba(255,255,255,1) 0%,rgba(218,210,216,1) 100%,rgba(30,87,153,1) 100%);
}

.gradient-vertical-alternate{
  background: linear-gradient(to bottom,  rgba(218,210,216,1) 0%,rgba(255,255,255,1) 100%);
}

.gradient-bottom-vertical{
  background: linear-gradient(to bottom,  rgba(30,87,153,1) 0%,rgba(255,255,255,1) 0%,rgba(218,210,216,1) 100%);
}

.gradient-bottom-vertical-joining{
  background: linear-gradient(to bottom,  rgba(30,87,153,1) 0%,rgba(255,255,255,1) 0%,rgba(218,210,216,1) 100%);
  padding-bottom: 4rem;
}

.gradient-bottom-vertical-alternate{
  background: linear-gradient(to bottom,  rgba(255,255,255,1) 0%,rgba(218,210,216,1) 100%);
}

.gradient-light-grey{
  background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(151,151,151,0.8244341681985294) 100%);
}

.french-grey{
  background: linear-gradient(to bottom, rgba(218,210,216,0.65) 0%,rgba(218,210,216,0.1) 84%,rgba(218,210,216,0) 100%);
}

.gradient-vertical-downwards{
  background: linear-gradient(0deg, rgba(151,151,151,0.8244341681985294) 0%, rgba(255,255,255,1) 100%);
}

.gradient-vertical-top-bottom{
  background: linear-gradient(to bottom,  rgba(30,87,153,1) 0%,rgba(218,210,216,1) 0%,rgba(255,255,255,1) 50%,rgba(218,210,216,1) 100%);
}
