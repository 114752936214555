.btn-primary{

  &.btn-elephant-grey{
    background-color: $elephant-grey;
    color: $white;
  }

  &.btn-ebony-black{
    background-color: $ebony-black;
    color: $white;
  }

  &.btn-padding{
    padding: 1rem 3.5rem;

    @include media-breakpoint-down(xl){
      padding: 1rem 2.5rem;
    }

    @include media-breakpoint-down(lg){
      padding: 0.5rem 2rem;
    }

  }
}

.btn-outline-dark{
  &.btn-padding{
    padding: 1.2rem 3rem;
  }
}

.btn-black{
  border: 3px solid black;
  color: black;
  padding: 1rem 2rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
