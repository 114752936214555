header{
  .container-fluid{
    @include media-breakpoint-up(lg) {max-width: 90%;}

    .navbar{
      padding: 2rem 0;

      .navbar-collapse {
        justify-content: flex-end;

        .navbar-nav {
          max-width: 975px;
          justify-content: space-between;
          width: 100%;

          .nav-item {
            padding: 0;
          }
        }
      }

      .navbar-toggler{
            position: relative;
            color: $white;
            padding-left: 2rem;
            &:focus,
            &:active{
                outline: 0;
            }
            .line{
                position: absolute;
                left: 0;
                display: block;
                height: 2px;
                background-color: $ebony-black;
                border-radius: 15px;
                width: 25px;
                transition: transform 0.2s ease-in-out;
                &.top{
                    top: 0px;
                    transform: rotate(45deg);
                }
                &.middle{
                    top: 13px;
                    display: none;
                }
                &.bottom{
                    bottom: 6px;
                    transform: rotate(-45deg);
                }
            }
            &.collapsed{
                .line{
                    transform: none;
                    &.top{top: -5px;}
                    &.middle{
                        display: block;
                        top: 1px;}
                    &.bottom{
                        bottom: -1px;
                    }
                }
            }
        }

      .navbar-brand{
        img{
          width: 18rem;
          @include media-breakpoint-down(xl){width: 9rem;}
        }
      }

      .nav-item{
        @include media-breakpoint-down(md){padding: 0;}
        a{
          color: $ebony-black;
          border-bottom: 2px solid $white;
          transition: 0.3s ease-in-out;
        }
        a:hover{
          border-bottom: 2px solid $orange;
        }
      }
    }
  }
}
