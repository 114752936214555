.feature-card {
  margin: 0rem 0 2rem 0;
  display: flex;
  flex-direction: column;
  height: 100%;

  @include media-breakpoint-down(sm) {
    margin: 0;
  }

  .feature-card-image {
    height: 23rem;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .feature-card-content {
    background-color: $white;
    padding: 6rem 6rem;

    h2 {
      color: $elephant-grey;
    }

    h3 {
      font-size: 1.2rem;
    }

    p {
      margin-top: 1rem;
      color: $grey;
    }

    a {
      text-transform: uppercase;
      margin-top: auto;

      i {
        font-size: 13px;
      }
    }
  }

  h2::after {
    content: '';
    display: block;
    height: 3px;
    width: 14%;
    margin-top: 5px;
    background-color: $orange;
  }

  h3::after {
    content: '';
    display: block;
    height: 3px;
    width: 23%;
    margin-top: 5px;
    background-color: $orange;
  }

  @include media-breakpoint-down(xl) {
    .feature-card-image {
      height: 18rem;
    }

    .feature-card-content {
      padding: 4rem 3rem;
      // margin-top: -50px;
      height: calc(100% - 18rem);
      display: flex;
      flex-direction: column;
    }
  }

  @include media-breakpoint-down(lg) {
    .feature-card-image {
      height: 17rem;
    }

    .feature-card-content {
      padding: 2rem 2rem;
      // margin-top: -40px;
      height: calc(100% - 17rem);
    }
  }

  @include media-breakpoint-down(md) {
    .feature-card-image {
      height: 32rem;
    }
    .feature-card-content {
      height: calc(100% - 32rem);
    }
  }

  @include media-breakpoint-down(sm) {
    .feature-card-image {
      height: 21rem;
    }
    .feature-card-content {
      height: calc(100% - 21rem);
    }
  }

  &.featured-events {
    margin: 1rem;

    .feature-card-image {
      height: 17rem;
    }

    .feature-card-content {
      padding: 4rem 2rem;

      h2 {
        font-weight: bold;
        font-size: 1.4rem;
      }

      h2::after {
        width: 23%;
      }
    }

    @include media-breakpoint-down(sm) {
      margin: 0.5rem 1rem;

      .feature-card-image {
        height: 15rem;
      }

      .feature-card-content {
        padding: 3rem 2rem;

        h2 {
          font-size: 1.1rem;
        }
      }
    }
  }
}

.feature-gallery-panel {
  position: relative;
  display: flex;
  flex-direction: row;

  @include media-breakpoint-only(lg) {
    flex-direction: column;
  }

  .gallery-image {
    width: 180%;
    text-align: center;
    background-color: #dad2d8;

    @include media-breakpoint-down(lg) {
      width: 100%;
    }

    @include media-breakpoint-down(sm) {
      height: 10rem;
    }

    img {
      max-height: 410px;
    }
  }

  .panel-info-box {
    padding: 1rem;
    margin: 3rem;
    width: 75%;    

    h5 {
      color: $elephant-grey;
    }

    &.alternate {
      background: none;
      padding: 0 1rem 1rem 0;
      margin: 0 1rem 1rem 0;

      @include media-breakpoint-only(lg) {
        margin-bottom: 2rem;
      }

      @include media-breakpoint-down(sm) {
        padding: 1.5rem;
        margin: 1rem;
      }

      h5 {
        font-size: 1.8rem;

        @include media-breakpoint-down(sm) {
          font-size: 1.2rem;
        }
      }
    }

    h5::after {
      content: '';
      display: block;
      height: 3px;
      width: 14%;
      margin-top: 5px;
      background-color: $orange;

      @include media-breakpoint-down(sm) {
        margin-top: 0rem;
      }
    }

    @include media-breakpoint-down(md) {
      background-color: $white;
      padding: 3rem;
      margin: 1rem;
      position: absolute;
      top: 21%;
      left: 9%;
    }

    @include media-breakpoint-down(md) {
      padding: 1.5rem;
      top: 0;
    }

    @include media-breakpoint-down(md) {
      padding: 1rem;
      position: relative;
      top: 0;
      left: 7%;
    }

    .panel-info-box-title {
      position: absolute;
      top: 4rem;
      text-align: right;
    }
  }
}

.gallery-thumbnail {
  padding: 4.5rem 1.4rem 5.5rem 2.5rem;

  @include media-breakpoint-down(md) {
    padding: 0.5rem 1.4rem 5.5rem 2.5rem;
  }

  @include media-breakpoint-down(sm) {
    margin: 0.5rem 0 0 0
  }

  .gallery-thumbnail-text {
    color: $elephant-grey;
    text-transform: capitalize;
    margin-bottom: 1rem;
    font-size: 1.3rem;
    font-weight: bold;

    @include media-breakpoint-down(md) {
      display: none;
    }
  }
}