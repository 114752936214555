footer{
  background-color: $elephant-grey;
  color: $white;
  width: 100%;

  .footer-container{
    display: inline-flex;
    justify-content: space-between;
    padding: 4rem 3rem;
    @include media-breakpoint-down(lg){font-size: 0.8rem;}
    @include media-breakpoint-down(md){
      flex-direction: column;
      align-items: center;
    }

    .footer-section{
      padding: 4rem 0.5rem;
      @include media-breakpoint-down(md){padding: 1rem 0.5rem;}
      
      .content{
        h5{
          font-weight: bold;
          margin-bottom: 1rem;
        }
        ul{column-count: 2;}
        a{color: $white;}
      }
    }
  }

  .bottom-bar{
    width: 100%;
    background-color: $ebony-black;
    color: $white;
    padding: 1.5rem 3rem;
    @include media-breakpoint-down(lg){font-size: 0.8rem;}
  }
}
