.members-carousel {
  margin-top: -5%;
  padding-bottom: 2%;

  @include media-breakpoint-down(md) {
    margin-top: 5rem;
  }
}

.members-card {
  padding: .5rem;
}

.gallery-page-block {
  background: linear-gradient(to bottom, rgba(30, 87, 153, 1) 0%, rgba(218, 210, 216, 1) 0%, rgba(255, 255, 255, 1) 50%, rgba(218, 210, 216, 1) 100%);
  min-height: 68rem;

  h1 {
    padding-top: 7%;
    padding-bottom: 1%;
  }
}

.carousel {
  @media only screen and (max-width: 640px) {
   min-height: 640px;
  }
}

.carousel-button-background {
  width: 10rem;
  height: 4rem;
  background-color: $white;
  position: absolute;
  right: 75px;

  span::after {

    content: '';
    display: block;
    height: 38px;
    width: 1%;
    margin-top: 1rem;
    background-color: #4E4E4E;
    position: absolute;
    top: 29%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}