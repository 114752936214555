* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
    font-family: 'Lato';
    src: url('#{$fontDir}/Lato-Regular.woff2') format('woff2'),
        url('#{$fontDir}/Lato-Regular.woff') format('woff'),
        url('#{$fontDir}/Lato-Regular.ttf') format('truetype'),
        url('#{$fontDir}/Lato-Regular.svg#Lato-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Lato';
    src: url('#{$fontDir}/Lato-Bold.woff2') format('woff2'),
        url('#{$fontDir}/Lato-Bold.woff') format('woff'),
        url('#{$fontDir}/Lato-Bold.ttf') format('truetype'),
        url('#{$fontDir}/Lato-Bold.svg#Lato-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'FormaDJRDisplay';
    src: url('#{$fontDir}/FormaDJRDisplay-Regular.woff2') format('woff2'),
        url('#{$fontDir}/FormaDJRDisplay-Regular.woff') format('woff'),
        url('#{$fontDir}/FormaDJRDisplay-Regular.ttf') format('truetype'),
        url('#{$fontDir}/FormaDJRDisplay-Regular.svg#FormaDJRDisplay-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'FormaDJRDisplay';
    src: url('#{$fontDir}/FormaDJRDisplay-Medium.woff2') format('woff2'),
        url('#{$fontDir}/FormaDJRDisplay-Medium.woff') format('woff'),
        url('#{$fontDir}/FormaDJRDisplay-Medium.ttf') format('truetype'),
        url('#{$fontDir}/FormaDJRDisplay-Medium.svg#FormaDJRDisplay-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
}
