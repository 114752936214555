/* CSS for about us page*/
.about-text {
  width: 15%;
  height: 50%;
  display: inline-block;
  z-index: 1;
  left: 20%;
  background-color: #FFFFFF;
  position: absolute;
  margin-top: 7.5rem;
}

.about-text u {
  text-decoration: none;
  border-bottom: 3px solid #E59500;
}

.about-us-images {
  padding-top: 6rem;
  padding-bottom: 6rem;
  width: 60%;
  height: 60%;

}

.about-text h2 {
  padding-top: 10%;
  padding-bottom: 6%;
  padding-right: 1.5rem;
}

.about-p {
  padding-right: 15%;
  padding-top: 4%;
  padding-bottom: 2%;

}

/* CSS for Programme Page;*/

#programme-table {
  margin-top: 2%;
}

.date {
  width: 25%;
}

.details {
  width: 75%;
}

#further-details {
  margin-bottom: 5rem;
  margin-top: 2rem;
}

.winter-events {
  background-color: $silver;
  width: 100%;
  height: 45rem;
}

.winter-events h1 {
  padding-top: 4%;
  padding-bottom: 2%;
}

.winter-events img {
  padding-top: 1%;
}

.winter-events tr {
  object-fit: fill;
}

.extra-margin {
  margin-top: 2rem;
}

.saturday-workshop {
  width: 100%;
  height: 50rem;
}

.saturday-workshop h1 {
  padding-top: 4%;
  padding-bottom: 2%;
}

.saturday-workshop p {
  padding: 2rem;
}

.saturday-workshop img {
  z-index: 1;
}

#workshop-content {
  z-index: 5;
  margin-right: -9rem;
}


.member-gallery {
  margin-top: 0;
}

.art-title {
  @include media-breakpoint-down(lg) {
    position: relative;
  }

  @include media-breakpoint-down(xl) {
    position: absolute;
    bottom: 0;
    text-align: right;
    margin-left: 9%;
    margin-bottom: 1rem;
  }
}

.gradient-margin-top {
  margin-top: 4rem;
}


.banner-title-margin {
  margin-left: 30%;
}

.container-mobile {
  @include media-breakpoint-down (sm) {
    // height:75rem;
    height: auto;
  }
}

#gallery-heading-left-padding {
  padding-left: 4rem;
}

.mobile-responsive {
  @include media-breakpoint-down(sm) {
    display: flex;
    flex-direction: column;

    img {
      margin: 1rem 0;
    }
  }
}

//  CSS for Gallery page
.w-90 {
  width: 90%;
}

.w-50 {
  width: 50%;
}

//CSS for online exhibition

.artists_list {
  grid-column: 1/10;

  @media only screen and (max-width: 640px) {
    grid-column: 1/13;
    grid-row: 2/3;
  }
}

.artwork_grid-container {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-auto-rows: auto;
  grid-gap: 2rem;
  padding-right: 15px;
  padding-left: 15px;

  @media only screen and (max-width: 640px) {
    grid-template-columns: unset;
    grid-auto-rows: auto;
    grid-gap: unset;

  }
}

.artist_work-container {
  width: 100%;

  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-auto-rows: auto;
  grid-gap: 2rem;

}

.artists_names-title {
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.work_descriptors {
  font-size: 0.875rem;
  margin-bottom: 0.5rem;
}

.artist_header-contact {
  & .artist-name {
    margin-bottom: 0.8rem;
    text-decoration: underline;
  }
}

.artist_header {
  margin-bottom: 2rem;

  & .bio-title {
    margin-bottom: 0.5rem;
  }

  & .bio {
    padding-bottom: 1rem;
  }
}

.artists_names {
  background: #e59500;
  grid-column: 10/13;
  position: relative;
  padding: 2rem;
  color: white;

  @media only screen and (max-width: 1024px) and (min-width: 640px) {
    padding: 1rem;

  }



  @media only screen and (max-width: 640px) {
    grid-column: 1/13;
    grid-row: 1/2;
    margin-bottom: 2rem;
  }

  & ul {
    padding: unset;
  }

  & li {
    list-style-type: none;
    font-size: 1.2rem;
    font-weight: bold;
  }

  & .names-title {
    font-size: 1rem;
    font-weight: 300;
    position: relative;
  }

  & .is-desktop {
    display: inline-block;
  }

  & .is-mobile {
    display: none;
    width: 100%;

    &:after {
      content: '';
      position: absolute;
      right: 0;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 0;
      height: 0;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-top: 8px solid #ffffff;
      clear: both;
      transition: transform .3s ease;
    }
  }


  @media only screen and (max-width: 640px) {
    height: 48px;
    overflow: hidden;
    padding: 0 1rem;


    & .artists_names-title {
      font-size: 1rem;
    }

    & .is-desktop {
      display: none;
    }

    & .is-mobile {
      display: inline-block;

      & .hide {
        display: none;
      }

      & .reveal {
        display: inline-block;
      }
    }

    & li {
      line-height: 48px;
      margin: 0;
    }

    &.dropdown {
      height: auto;

      & .is-mobile {

        width: 100%;

        & .hide {
          display: inline-block;
        }

        & .reveal {
          display: none;
        }

        &:after {
          content: '';
          transform: translate(-50%, -50%) rotate(180deg);
        }
      }
    }
  }
}

.work-title_price {
  margin-top: 1rem;
  padding-top: 1rem;
  border-top: 1px solid #e59500;

  & .title,
  .price {
    font-size: 1rem;
    letter-spacing: 1px;
  }

  & .title {
    font-weight: 300;
  }

  & .price {
    margin-bottom: 1rem;
  }

}

.artist_work-single {
  & img {
    width: 100%;
    object-fit: contain;
    box-shadow: 5px 5px rgb(0, 0, 0);
    margin-bottom: 5px;
  }
}