section{
  position: relative;

  .carousel-inner{
    max-height: 640px;
  }

  .white-block{
    background-color: $white;
    width: 45rem;
    height: 100%;
    padding: 2rem;
    position: absolute;
    top: 0;
    left: 11rem;

    .content{
      padding: 1rem;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      h1{
        font-size: 4rem;
        color: $elephant-grey;
      }

      p {
        @include media-breakpoint-up(md) {
          padding: 1.5rem 0;
        }

        @include media-breakpoint-up(sm) {
          display: block;
        }

        &:empty {
          display: none;
        }
      }
    }

    @include media-breakpoint-down(xl){
      width: 37rem;
      padding: 1rem;
    }

    @include media-breakpoint-down(lg){
      width: 40rem;
      left: 8rem;
    }

    @include media-breakpoint-down(md){
      padding: 0;
      width: 40rem;
      left: 3rem;
    }

    @include media-breakpoint-down(sm){
      width: 60%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      .content{
        padding: 0;
        h1{font-size: 2rem;}
      }

    }
  }

  .white-block h1::after{
    content: '';
    display: block;
    height: 5px;
    width: 30%;
    margin-top: 5px;
    background-color: $orange;
  }
}

.banner{
  width: 100%;
  height: 16rem;
  position: relative;
  
  @include media-breakpoint-down(xs) {
    height: 100%;
    min-height: 16rem;
  }

  .banner-block{
    width: 100%;
    height: 16rem;
    background-color: $ebony-black;
    position: absolute;
    top: 25%;
    left: 50%;
    transform: translate(-50%, -50%);

    @include media-breakpoint-down(sm){
      width: 95%;
      height: 18rem;
    }

    @include media-breakpoint-down(xs) {
      height: auto;
      position: static;
      transform: translateY(-20%);
      margin-top: 0px;
    }

    &.alternate{
      background-color: $orange;
      top: 75%;

      @include media-breakpoint-down(xs) {
        transform: translateY(20%);
      }
    }

    .banner-block-content{
      color: $white;
      padding: 4rem 2rem;
      @include media-breakpoint-down(lg){padding: 4rem;}
      @include media-breakpoint-down(md){padding: 5rem 2rem;}
      @include media-breakpoint-down(sm){padding: 2.5rem;}

      &.alternate{
        color: $ebony-black;
        h2::after{
          content: '';
          display: block;
          height: 3px;
          width: 7%;
          margin-top: 1rem;
          background-color: $white;
        }
      }

      h2::after{
        content: '';
        display: block;
        height: 3px;
        width: 7%;
        margin-top: 1rem;
        background-color: $orange;
      }

    }
  }
}

.page-banner-custom {
  @include media-breakpoint-down(xs){
    height: 24rem!important;
  }
}

.page-banner{
  width: 100%;
  height: 16rem;
  background-color: $orange;

  @include media-breakpoint-down(xs) {
    height: auto;
    min-height: 16rem;
  }

  &.alternate{
    background-color: $elephant-grey;

    .page-banner-content {
      p {
        color: $white;
      }
    }
  }

  .page-banner-content{
    padding: 3rem 0 3rem 0;

    .banner-column {
        display: flex;

        @include media-breakpoint-down(sm) {
          justify-content: center;
        }
    }

    h1{
      padding: 0 2rem 0 0;
      margin-bottom: 0;
      color: $white;
      line-height: 1;
      @include media-breakpoint-down(xl){
        font-size: 4rem;
      }
      @include media-breakpoint-down(lg){
        font-size: 3rem;
        padding: 2rem;
      }
      @include media-breakpoint-down(sm){
        text-align: center;
        width: 100%;
      }
      @include media-breakpoint-up(md){
        display: flex;
        justify-content: flex-end;
      }
    }

    #h1-text-center{
      padding: 4rem 2rem 0 0;
      margin-bottom: 0;
      color: $white;
      @include media-breakpoint-down(xl){
        font-size: 4rem;
      }
      @include media-breakpoint-down(lg){
        font-size: 3rem;
        padding: 2rem;
      }
      @include media-breakpoint-down(sm){
        text-align: center;
      }
      @include media-breakpoint-up(md){
        display: flex;
        justify-content: flex-end;
      }
    }

    h1::after{
      content: '';
      display: block;
      height: 3px;
      width: 100%;
      margin-top: 5px;
      background-color: $white;
      @include media-breakpoint-down(xl){display: none;}
      @include media-breakpoint-down(sm){display: block}
    }

    p{
      color: $elephant-grey;
      margin-bottom: 0;
      padding: 2rem 0;
      @include media-breakpoint-down(md){padding: 2.5rem 0;}
      @include media-breakpoint-down(sm){
        padding: 0 1.5rem;
        text-align: center;
      }
    }

    p::before{
      content: '';
      display: block;
      height: 130px;
      width: 1px;
      margin-top: 5px;
      background-color: $white;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: -20px;
      @include media-breakpoint-down(sm){display: none;}
    }

      p.custom-height::before {
        content: '';
        display: block;
        height: 130px;
        width: 1px;
        margin-top: 5px;
        background-color: $white;
        position: absolute;
        top: 22px;
        left: -20px;
        @include media-breakpoint-down(sm){display: none;}
        @include media-breakpoint-up(md){height: 180px;}
      }

  }
}

.orange-banner{
  background-color: $orange;
  width: 90%;
  height: 12rem;
  margin: 3rem 0;
  position: relative;
  bottom: 1rem;

  .content{padding: 3.5rem;}

  @include media-breakpoint-down(md){height: 15rem;}

  @include media-breakpoint-down(sm){
    height: 18rem;
    .content{padding: 2rem;}
  }
}
