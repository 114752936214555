.event-block{
  background: linear-gradient(to bottom,  rgba(30,87,153,1) 0%,rgba(218,210,216,1) 0%,rgba(255,255,255,1) 50%,rgba(218,210,216,1) 100%);
  min-height: 48rem;

  .event-header{
    position: relative;
    padding: 4rem 4rem 0 4rem;
    h2{font-size: 2.5rem;}

    h2::after{
      content: '';
      display: block;
      height: 3px;
      width: 6%;
      margin-top: 5px;
      background-color: $elephant-grey;
    }

    .carousel-button-background{
      width: 10rem;
      height: 4rem;
      background-color: $white;
      position: absolute;
      top: 125px;
      right: 63px;

      span::after{
        content: '';
        display: block;
        height: 38px;
        width: 1%;
        margin-top: 1rem;
        background-color: #4E4E4E;
        position: absolute;
        top: 29%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    @include media-breakpoint-down(sm){
      padding: 3rem 2rem 0 2rem;
      h2{font-size: 1.2rem;}
    }
  }

  @include media-breakpoint-down(sm){min-height: 43rem;}
}

.custom-button-background{
  width: 10rem;
  height: 4rem;
  background-color: $white;
  position: relative;
  top: -55px;
  left: 934px;

  span::after{
    content: '';
    display: block;
    height: 38px;
    width: 1%;
    margin-top: 1rem;
    background-color: #4E4E4E;
    position: absolute;
    top: 29%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.gallery-block{
  .gallery-header{
    padding: 3rem 0 3rem 0;

    @include media-breakpoint-down(sm){padding: 3rem 0 0 0;}

    h2::after{
      content: '';
      display: block;
      height: 3px;
      width: 4%;
      margin-top: 1rem;
      background-color: $elephant-grey;
    }

  }
}

.generic-white-block-container{
  position: relative;

  @include media-breakpoint-down(lg){min-height: 80rem;}
  @include media-breakpoint-down(sm){min-height: 76rem;}

  .generic-white-block{
    background-color: $white;
    width: 36%;
    position: absolute;
    top: 5rem;
    left: 6rem;

    @include media-breakpoint-down(md){
      position: relative;
      width: 100%;
      top: 0;
      left: 0;
    }

    .generic-content{
      padding: 3.5rem;

      h2::after{
        content: '';
        display: block;
        height: 3px;
        width: 18%;
        margin-top: 1rem;
        background-color: $orange;
      }

      p{color: $ebony-black;}
    }
  }
}


.beige-block-container{
  position: relative;

  .beige-block{
    background-color: $beige;
    width: 28rem;
    position: absolute;
    top: 3rem;
    left: 0;
    z-index: 1;
    @include media-breakpoint-down(lg){
      left: 0rem;
    }
    @include media-breakpoint-down(sm){
      position: relative;
      top: 0;
      left: 0;
      width: 100%;
    }

    &.alternate{
      position: absolute;
      right: 0;
      left: auto;
      @include media-breakpoint-down(lg){right: 0;}
      @include media-breakpoint-down(sm){
        position: relative;
        top: 0;
        left: 0;
        width: 100%;
      }
    }

    .content{
      padding: 2rem;
      h2{color: $elephant-grey;}
      @include media-breakpoint-down(sm){padding: 1.5rem;}

      h2::after{
        content: '';
        display: block;
        height: 3px;
        width: 18%;
        margin-top: 5px;
        background-color: $orange;
      }

    }
  }

  .beige-block-wider{
    background-color: $beige;
    width: 69rem;
    position: absolute;
    top: 3rem;
    left: 0;
    @include media-breakpoint-down(lg){
      left: 0rem;
      max-width: 100%;
    }
    @include media-breakpoint-down(sm){
      position: relative;
      top: 0;
      left: 0;
      width: 100%;
    }

    &.alternate{
      position: absolute;
      right: 0;
      left: auto;
      @include media-breakpoint-down(lg){
        position: relative;
        top: 0;
        left: 0;
        width: 100%;
      }
    }

    .content{
      padding: 2rem;
      h2{color: $elephant-grey;}
      @include media-breakpoint-down(sm){padding: 1.5rem;}

      h2::after{
        content: '';
        display: block;
        height: 3px;
        width: 18%;
        margin-top: 5px;
        background-color: $orange;
      }

    }
  }

  .beige-block-no-pos{
    background-color: $beige;
    width: 69rem;
    top: 3rem;
    left: 0;
    @include media-breakpoint-down(lg){
      left: 0rem;
    }
    @include media-breakpoint-down(sm){
      position: relative;
      top: 0;
      left: 0;
      width: 100%;
    }

    &.alternate{
      position: absolute;
      right: 0;
      left: auto;
      @include media-breakpoint-down(lg){
        position: relative;
        top: 0;
        left: 0;
        width: 100%;
      }
    }

    .content{
      padding: 2rem;
      h2{color: $elephant-grey;}
      @include media-breakpoint-down(sm){padding: 1.5rem;}

      h2::after{
        content: '';
        display: block;
        height: 3px;
        width: 18%;
        margin-top: 5px;
        background-color: $orange;
      }

    }
  }
}

.orange-block-container{
  position: relative;

  .orange-block{
    background-color: $orange;
    width: 24rem;
    position: absolute;
    left: 0;

    @include media-breakpoint-down(lg){left: 0rem;}

    @include media-breakpoint-down(sm){
      position: relative;
      width: 100%;
    }

    .content{
      padding: 3rem;
      p{color: $ebony-black;}
    }
  }
}


.two-row-background-image{
  padding: 5rem;
  width: 80%;

  @include media-breakpoint-down(lg){
    width: 100%;
    padding: 2rem 1rem;
  }
}


section{
  .mobile-column{
    @include media-breakpoint-down(md){flex-direction: column;}
    @include media-breakpoint-down(xl){display: flex;}

    .ebony-black-block{
      background-color: $ebony-black;
      width: 50%;
      height: 64rem;
      margin-top: 7rem;
      margin-bottom: 9rem;

      @include media-breakpoint-down(md){
        width: 100%;
        height: 54rem;
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;
      }

      .content{
        color: $white;
        padding: 6rem 9rem 6rem 6rem;
        @include media-breakpoint-down(sm){padding: 3rem;}
      }
    }

    .tangerine-orange-block{
      background-color: $orange;
      height: 60rem;
      padding: 7rem;
      h4{font-weight: bold;}

      @include media-breakpoint-down(md){
        width: 100%;
        padding: 3rem;
      }

      @include media-breakpoint-up(lg){
        position: relative;
        width: 59%;
        top: 10rem;
        right: 0;
        margin-left: -6rem;
      }
    }
  }
}


.grey-bar{
  width: 100%;
  height: 10rem;
  background-color: $silver;
}
