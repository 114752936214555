// Paths

	$fontDir: '../../fonts';
	$imgDir: '../../img';

// Colours

	$black: 						#000;
  $white: 						#FFFFFF;
  $ebony-black: 			#050517;
  $elephant-grey: 		#143642;
  $alpine-gold: 			#BE9639;
	$silver: 						#C0C0C5;
	$beige: 						#F8E4BF;
	$light-beige:				#FEF9EF;
	$grey: 							rgba(5, 5, 23, 0.6);
	$orange: 						#E59500;
	$silver:						#c0c0c5;

// Bootstrap Overidesx

  $headings-font-family: 'FormaDJRDisplay';
  $font-family-base: 'Lato';
  $primary: $orange;
	$dark: $ebony-black;
  $border-radius: 0;
