.padding-y {
  padding-top: 2rem;
  padding-bottom: 5rem;

  @include media-breakpoint-down(sm) {
    padding-bottom: 8rem;
    padding-top: 0rem;
  }
}

.custom-padding-y-3 {
  padding-top: 2rem;
  padding-bottom: 7rem;

  @include media-breakpoint-down(sm) {
    padding-bottom: 10rem;
    padding-top: 3rem;
  }
}

.padding-even-responsive {
  padding: 7rem;
}

.padding-y-lg {
  padding-top: 7rem;
  padding-bottom: 5rem;

  @include media-breakpoint-down(sm) {
    padding-top: 3rem;
    padding-bottom: 4rem;
  }
}

.custom-padding-y-lg {
  padding-top: 7rem;
  padding-bottom: 5rem;

  @include media-breakpoint-down(sm) {
    padding-top: 3rem;
    padding-bottom: 0rem;
  }
}

.header-padding {
  padding-top: 6rem;
}

.custom-padding-right {
  padding-right: 0;

  @include media-breakpoint-down(md) {
    padding-right: 15px;
    padding-left: 15px;
  }
}

.custom-padding-left {
  padding-left: 0;

  @include media-breakpoint-down(md) {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.custom-padding-bottom {
  padding-bottom: 0rem;
}

.padding-bottom {
  padding-bottom: 16rem;

  @include media-breakpoint-down(sm) {
    padding-bottom: 4rem;
  }
}

.padding-bottom-11 {
  padding-bottom: 11rem;

  @include media-breakpoint-down(sm) {
    padding-bottom: 4rem;
  }
}

.event-carousel-padding {
  padding: 3rem;

  @include media-breakpoint-down(sm) {
    padding: 1.5rem;
  }
}

.member-page-left-padding {
  padding-left: 1rem;
}

#members-title-padding {
  margin-left: 0;
}

.gallery-carousel-padding {
  padding: 2rem 5rem;

  @include media-breakpoint-down(sm) {
    padding: 0 2rem 1rem 2rem;
  }
}

.extra-padding {
  padding-bottom: 7rem;
}

.padding-y-3 {
  padding-top: 3rem;
}

.padding-y-5 {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.padding-y-10 {
  padding-top: 3rem;
  padding-bottom: 10rem;
}

.padding-y-13 {
  padding-top: 3rem;
  padding-bottom: 13rem;
}

.padding-y-26 {
  padding-top: 3rem;
  padding-bottom: 3rem;

  @include media-breakpoint-up(xl) {
    padding-bottom: 26rem;
  }
}