.custom-slick-carousel{

  .slick-prev, .slick-next{
    font-size: 2rem;

    &:before{
      font-family: "fontello";
      font-style: normal;
      font-weight: normal;
      speak: none;
      display: inline-block;
      text-decoration: inherit;
      width: 1em;
      margin-right: .2em;
      text-align: center;
      font-variant: normal;
      text-transform: none;
      line-height: 1em;
      margin-left: .2em;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
  }

  .slick-prev:before{
    content: '\e800';
    color: black;
    font-size: 1.5rem;
  }

  .slick-next:before{
    content: '\e801';
    color: black;
    font-size: 1.5rem;
  }

  .slick-prev{
    top: -83px;
    left: auto;
    right: 135px;
  }

  .slick-next{
    top: -83px;
    right: 50px;
  }

  &.custom-nav{
    .slick-prev{
      position: absolute;
      z-index: 1;
      top: 43%;
      left: 0;
    }
    .slick-next{
      top: 43%;
      right: 0;
    }
  }

  &.custom-nav-alternate{
    .slick-prev{
      position: absolute;
      z-index: 1;
      top: 39%;
      right: 105%;
      @include media-breakpoint-up(md){
        right: 98%;
      }
      @media only screen and (max-width: 640px){
        right: 100%
      }
      @media only screen and (max-width: 585px){
        right: 94%
      }
      @media only screen and (max-width: 436px){
        right: 93%;
      }
      @media only screen and (max-width: 387px){
        right: 92%;
      }
      @media only screen and (max-width: 350px){
        right: 91%;
      }
      @media only screen and (max-width: 323px){
        right: 90%;
      }
    }
    .slick-next{
      top: 39%;
      left: 105%;
      @include media-breakpoint-up(md){
        left: 98%;
      }
      @media only screen and (max-width: 640px){
        left: 100%
      }
      @media only screen and (max-width: 585px){
        left: 94%
      }
      @media only screen and (max-width: 436px){
        left: 93%;
      }
      @media only screen and (max-width: 387px){
        left: 92%;
      }
      @media only screen and (max-width: 350px){
        left: 91%;
      }
      @media only screen and (max-width: 323px){
        left: 90%;
      }
    }
  }
}

.members-carousel {
  .slick-arrow {
    @include media-breakpoint-down(lg) {
      background: white;
      padding: 2rem 43px;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;

      &:first-child {
        &::after {
          content: '';
          display: block;
          height: 38px;
          width: 1%;
          margin-top: 1rem;
          background-color: #4e4e4e;
          position: absolute;
          top: 29%;
          right: 0;
          transform: translate(-50%,-50%);
        }
      }

      &.slick-prev {
        right: 85px;
      }
      &.slick-next {
        right: 0;
      }
    }

    @include media-breakpoint-down(xs) {
      &.slick-prev,
      &.slick-next {
        right: auto;
      }
      &.slick-prev {
        left: 0;
      }
      &.slick-next {
        left: 85px;
      }
    }
  }
}

.gallery-carousel{
  .slick-dots{
    position: absolute;
    bottom: 30rem;
    z-index: 1;

    @include media-breakpoint-only(lg) {
      bottom: 27rem;
    }

    @include media-breakpoint-down(md) {
      display: none!important;
    }

    li {
      width: 12px;
    }

    li button:before{
      font-size: 45px;
      color: $ebony-black;
    }
  }
}

.slick-slide img {
  display: inline-block;
}

.slick-dotted.slick-slider {
  margin-bottom: -65px;
}