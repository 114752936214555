.programme-table{
  width: 100%;
  color: $grey;
  
  tbody{
    tr{
      td{
        padding: 27.99px;
        .date{width: 20%;}
        .details{width: 80%;}

        @include media-breakpoint-down(sm){
          padding: 13px;
        }
      }
    }
  }
}

.verticle-white-line::before{
  content: '';
  width: 2px;
  height: 681px;
  background-color: $white;
  position: absolute;
  top: 0;
  left: 288px;

  @include media-breakpoint-down(lg){
    height: 732px;
    left: 250px;
  }

  @include media-breakpoint-down(md){
    height: 893px;
    left: 186px;
  }

  @include media-breakpoint-down(sm){
    height: 926px;
    left: 114px;
  }

}

.table-borderless>tbody>tr:nth-child(odd)>td,
.table-borderless>tbody>tr:nth-child(odd)>th {
       background-color: $beige;
     }

.table-borderless>tbody>tr:nth-child(even)>td,
.table-borderless>tbody>tr:nth-child(even)>td {
       background-color: $light-beige;
     }

.flex-grid-thirds {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.flex-grid-thirds .col {
  width: 32%;
}

@media (max-width: 400px) {
  .flex-grid-thirds {
    display: block;
    .col {
      width: 100%;
      margin: 0 0 10px 0;
    }
  }
}

.img-row {
  display: -ms-flexbox; /* IE10 */
  display: flex;
  -ms-flex-wrap: wrap; /* IE10 */
  flex-wrap: wrap;
  padding: 0 4px;
}

.img-col {
  -ms-flex: 25%; /* IE10 */
  flex: 25%;
  max-width: 25%;
  padding: 0 4px;
}

.img-col img {
  margin-top: 8px;
  vertical-align: middle;
  width: 100%;
}

/* Responsive layout - makes a two column-layout instead of four columns */
@media screen and (max-width: 800px) {
  .img-col {
    -ms-flex: 50%;
    flex: 50%;
    max-width: 50%;
  }
}

/* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
  .img-col {
    -ms-flex: 100%;
    flex: 100%;
    max-width: 100%;
  }
}

.img-center {
  @include media-breakpoint-up(md){
    position: relative;
    bottom: 9rem;
  }
}